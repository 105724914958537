import axios from "axios";
import helpers from "../shared/layouts/helpers";
class AuthService {
    login(username, password, connectTo) {
        return axios
            .post(helpers.getAPI(connectTo) + "/partner/api/login", JSON.stringify({username: username, password: password, country: connectTo}))
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem("mentechPartnerDetails2", JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    logout() {
        localStorage.removeItem("mentechPartnerDetails2");
        window.location.reload();
    }


    getCurrentUser() {
        return JSON.parse(localStorage.getItem('mentechPartnerDetails2'));
    }

    refreshToken() {
        let user = this.getCurrentUser()
        user.accessToken = null;
        localStorage.setItem("mentechPartnerDetails2", JSON.stringify(user));
        const headers = {
            'X-Authorization': user.refreshToken
        }
        return axios
            .post(helpers.getAPI() + "/partner/api/refresh/token", null, {headers: headers})
            .then(response => {
                let user = this.getCurrentUser();
                user = response.data;
                localStorage.setItem("mentechPartnerDetails2", JSON.stringify(user));

                return response.data;
            });
    }
}

export default new AuthService();