import React, { Component } from 'react'
import InternalError from './InternalError/InternalError'
import axios from "axios";
import helpers from "./layouts/helpers";

class ErrorBoundary extends Component {
  state = {
    error: '',
  };

  componentDidCatch(error, info) {

  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  render() {
    if (this.state.error) {
      return <InternalError/>;
    }

    return this.props.children;
  }

}

export default ErrorBoundary;

