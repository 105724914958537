import React, { Component } from 'react'
import { BrowserRouter as AppRouter, Route, Switch, Redirect } from 'react-router-dom'
import NotFound from './NotFound'

// Routes
import { DefaultLayout, titleTemplate, defaultRoute, routes } from '../routes'
import helpers from "./layouts/helpers";
import Login from "../components/Login";
import VerifyLogin from "../components/VerifyLogin";
import Register from "../components/Register";

// ---
// Main route component

class AnonymousRouter extends Component {
  constructor(props) {
    super(props);

  }

  setTitle(title) {
      document.title = titleTemplate.replace('%s', title)
  }


  render() {
    return (
      <AppRouter basename={process.env.REACT_APP_BASENAME}>
        <Switch>
            <Route
                path={"/verifyLogin/:token/:country"}
                exact={true}
                render={props => {
                    return <VerifyLogin {...props}/>
                }}
                key={"/verifyLogin"}
            />
            <Route path="/register/:guid/:country" component={Register} />
            <Route path="*" component={Login} />
        </Switch>
      </AppRouter>
    )
  }
}

export default AnonymousRouter
