import React, {Component, useState} from 'react'
import {Modal, Badge, Button, Card, Form, Nav, Tab, Tabs, Row, Col, Dropdown} from 'react-bootstrap'
import axios from "axios";
import Loader from "../../shared/Loader";
import helpers from "../../shared/layouts/helpers";
import Swal from "sweetalert2";
import AuthService from "../../services/auth-service";

export default class DepartmentSwitcher extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            department: helpers.getUser().departmentId
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true});

        axios.get(helpers.getAPI() + '/partner/api/switch/department/'+this.state.department).then(res => {
            let user = helpers.getUser();
            user.departmentId = res.data.departmentId;
            user.company = res.data.company;
            helpers.setUser(user);
            
            this.handleClose();
            window.location.reload();
        }).catch(error => {
            Swal.fire(
                'Something went wrong..',
                helpers.parseError(error),
                'error'
            )
        });

    };

    handleShow = () => (
        this.setState({show: true})
    );

    handleClose = () => (
        this.setState({show: false})
    );

    render(){
        if ( (helpers.getUser().id != 27615 && [49, 58, 52, 5177, 5178, 5179, 6028].indexOf(helpers.getUser().departmentId) == -1) || helpers.getUser().connectTo != 'DK') {
            return null;
        }

        return (
            <>
                <Dropdown.Item onClick={this.handleShow}>
                    <i className="ion ion-ios-cube"></i> &nbsp; Switch department
                </Dropdown.Item>

                <Modal show={this.state.show} onHide={this.handleClose} backdrop="static" size={'sm'}>
                    <Form onSubmit={this.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Switch department
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            {this.state.loading && <Loader/>}
                            <Form.Group>
                                <Form.Control required as={"select"} name={"department"} value={this.state.department} onChange={this.handleInputChange}>
                                    <option disabled value={""}>Please select an option</option>
                                    {helpers.getUser().id == 27615 && <>
                                        <option value="53">Scalepoint </option>
                                        <option value="5263">Tryg forsikring</option>
                                        <option value="48">Alka</option>
                                        <option value="57">GF Forsikring</option>
                                        <option value="59">Gjensidige</option>
                                        <option value="56">Popermo</option>
                                        <option value="61">Lokal Forsikring</option>
                                        <option value="970">Nykredit Forsikring</option>
                                        <option value="52">Lærerstandens Brandforsikring</option>
                                        <option value="5177">Runa Forsikring</option>
                                        <option value="5178">FDM Forsikring</option>
                                        <option value="5179">Bauta Forsikring</option>
                                        <option value="6028">PFA forsikring</option>
                                    </>}

                                    {[49, 58].indexOf(helpers.getUser().departmentId) !== -1 && <>
                                        <option value="49">Topdanmark</option>
                                        <option value="58">Danske Forsikring</option>
                                    </>}

                                    {[52, 5177, 5178, 5179, 6028].indexOf(helpers.getUser().departmentId) !== -1 && <>
                                        <option value="52">Lærerstandens Brandforsikring</option>
                                        <option value="5177">Runa Forsikring</option>
                                        <option value="5178">FDM Forsikring</option>
                                        <option value="5179">Bauta Forsikring</option>
                                        <option value="6028">PFA Forsikring</option>
                                    </>}

                                </Form.Control>
                            </Form.Group>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                            <Button type={"submit"} variant={"primary"}>Switch</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </>
        )
    }
}
