export default {

  get _layoutHelpers () {
    return window.layoutHelpers
  },

  _exec (fn) {
    return this._layoutHelpers && fn()
  },

  getLayoutSidenav () {
    return this._exec(() => this._layoutHelpers.getLayoutSidenav()) || null
  },

  getSidenav () {
    return this._exec(() => this._layoutHelpers.getSidenav()) || null
  },

  getLayoutNavbar () {
    return this._exec(() => this._layoutHelpers.getLayoutNavbar()) || null
  },

  getLayoutFooter () {
    return this._exec(() => this._layoutHelpers.getLayoutFooter()) || null
  },

  getLayoutContainer () {
    return this._exec(() => this._layoutHelpers.getLayoutContainer()) || null
  },

  isSmallScreen () {
    return this._exec(() => this._layoutHelpers.isSmallScreen())
  },

  isLayout1 () {
    return this._exec(() => this._layoutHelpers.isLayout1())
  },

  isCollapsed () {
    return this._exec(() => this._layoutHelpers.isCollapsed())
  },

  isFixed () {
    return this._exec(() => this._layoutHelpers.isFixed())
  },

  isOffcanvas () {
    return this._exec(() => this._layoutHelpers.isOffcanvas())
  },

  isNavbarFixed () {
    return this._exec(() => this._layoutHelpers.isNavbarFixed())
  },

  isFooterFixed () {
    return this._exec(() => this._layoutHelpers.isFooterFixed())
  },

  isReversed () {
    return this._exec(() => this._layoutHelpers.isReversed())
  },

  setCollapsed (collapsed, animate = true) {
    this._exec(() => this._layoutHelpers.setCollapsed(collapsed, animate))
  },

  toggleCollapsed (animate = true) {
    this._exec(() => this._layoutHelpers.toggleCollapsed(animate))
  },

  setPosition (fixed, offcanvas) {
    this._exec(() => this._layoutHelpers.setPosition(fixed, offcanvas))
  },

  setNavbarFixed (fixed) {
    this._exec(() => this._layoutHelpers.setNavbarFixed(fixed))
  },

  setFooterFixed (fixed) {
    this._exec(() => this._layoutHelpers.setFooterFixed(fixed))
  },

  setReversed (reversed) {
    this._exec(() => this._layoutHelpers.setReversed(reversed))
  },

  update () {
    this._exec(() => this._layoutHelpers.update())
  },

  setAutoUpdate (enable) {
    this._exec(() => this._layoutHelpers.setAutoUpdate(enable))
  },

  on (event, callback) {
    this._exec(() => this._layoutHelpers.on(event, callback))
  },

  off (event) {
    this._exec(() => this._layoutHelpers.off(event))
  },

  init () {
    this._exec(() => this._layoutHelpers.init())
  },

  destroy () {
    this._exec(() => this._layoutHelpers.destroy())
  },

  hasRole(roles) {
    if (!roles) return true;
    let userRoles = JSON.parse(localStorage.getItem('mentechPartnerDetails2')).roles;

    if (!Array.isArray(roles)) return userRoles.indexOf(roles) !== -1;

    for (let i=0; i<roles.length; i++) {
      if (userRoles.indexOf(roles[i]) !== -1) {
        return true
      }
    }

    return false;
  },

  hasAddon(addon) {
    let addons = JSON.parse(localStorage.getItem('mentechPartnerDetails2')).addons;

    return addons.indexOf(addon) !== -1;
  },

  getUser() {
    let user = JSON.parse(localStorage.getItem('mentechPartnerDetails2'));

    return user;
  },

  setUser(user) {
    localStorage.setItem('mentechPartnerDetails2', JSON.stringify(user));

    return user;
  },

  getFirstName() {
    let user = JSON.parse(localStorage.getItem('mentechPartnerDetails2')).fullName;

    return user.split(" ")[0];
  },

  getMonthName(month) {
    return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][month];
  },

  getCurrency() {
    return JSON.parse(localStorage.getItem('mentechPartnerDetails2')).currency
  },

  getCompanyId() {
    return parseInt(this.getUser().countryId.replace(/[^\d.-]/g, ''));
  },

  canEditDevice(device) {
    if (this.getUser().id === device.userId) {
      return true;
    }

    if (this.hasRole('ROLE_CUSTOMER_SUPER_ADMIN_USER')) {
      return true;
    }

    return false;
  },

  getAPI(country) {
    let connectTo =  (country ? country : this.getUser().connectTo);
    let host = window.location.hostname.includes("refurbee") ? "refurbee" : "mentech";

    if (connectTo && connectTo.includes('-')) {
       host = connectTo.includes("R-") ? "refurbee" : "mentech";
       connectTo = connectTo.split("-").pop();
    } else {
      host = window.location.hostname.includes("refurbee") ? "refurbee" : "mentech";
    }

    let list = JSON.parse(process.env.REACT_APP_API_LIST)[0][host];
    for(var i = 0; i < list.length; i++) {
      if(list[i].code === connectTo) {
        return list[i].api;
      }
    }
    return process.env.REACT_APP_API_URL_DA[0][host];
  },

  getLogo() {
    let host = window.location.hostname.includes("refurbee") ? "refurbee" : "mentech";
    return process.env.PUBLIC_URL + '/img/'+JSON.parse(process.env.REACT_APP_LOGO)[host];
  },

  getSystem() {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    let host = window.location.hostname.includes("refurbee") ? "Refurbee" : "Mentech";
    link.href = process.env.PUBLIC_URL + (host == "Refurbee" ? '/favicon-refurbee.png' : "/favicon.png");

    return host;
  },

  isValidCountry(country) {
    let host = window.location.hostname.includes("refurbee") ? "refurbee" : "mentech";
    let list = JSON.parse(process.env.REACT_APP_API_LIST)[0][host];
    for(var i = 0; i < list.length; i++) {
      if(list[i].code === country) {
        return true
      }
    }
    return false;
  },

  getHost() {
    return window.location.hostname.includes("refurbee") ? "refurbee" : "mentech";
  },

  // Internal
  //

  _removeClass (cls) {
    this._exec(() => this._layoutHelpers._removeClass(cls))
  },

  parseError(error)
  {
    if (
        !error.response ||
        !error.response.data ||
        (typeof error.response.data === 'string' && !error.response.data.includes("Swal.fire"))
    ) {
      return "";
    }

    return error.response.data.split(',')[1].replace("'", "").replace("'", "");
  },

  labels(config) {
    var cfg = config || {};
    var min = cfg.min || 0;
    var max = cfg.max || 100;
    var count = cfg.count || 8;
    var step = (max - min) / count;
    var decimals = cfg.decimals || 8;
    var dfactor = Math.pow(10, decimals) || 0;
    var prefix = cfg.prefix || '';
    var values = [];
    var i;

    for (i = min; i < max; i += step) {
      values.push(prefix + Math.round(dfactor * i) / dfactor);
    }

    return values;
  },

  months(config) {
    var MONTHS =  [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    var cfg = config || {};
    var count = cfg.count || 12;
    var section = cfg.section;
    var values = [];
    var i, value;

    for (i = 0; i < count; ++i) {
      value = MONTHS[Math.ceil(i) % 12];
      values.push(value.substring(0, section));
    }

    return values;
  },

  color(index) {
    var COLORS = [
      '#4dc9f6',
      '#f67019',
      '#f53794',
      '#537bc4',
      '#acc236',
      '#166a8f',
      '#00a950',
      '#58595b',
      '#8549ba'
    ];

    return COLORS[index % COLORS.length];
  },

  getPictureURL() {
    let v = new Date().getTime();
    return "https://api.mentech.dk/nsp/get/product/image?v="+v+"&url=";
  },

  getThumbnailURL() {
    return "https://api.mentech.dk/nsp/product/gallery_thumbs/";
  },

  getChartColor(c) {
    var CHART_COLORS = {
      red: 'rgb(255, 99, 132)',
      orange: 'rgb(255, 159, 64)',
      yellow: 'rgb(255, 205, 86)',
      green: 'rgb(75, 192, 192)',
      blue: 'rgb(54, 162, 235)',
      purple: 'rgb(153, 102, 255)',
      grey: 'rgb(201, 203, 207)'
    };

    return CHART_COLORS[c];
  },

  copyToClipboard(text, Swal) {
    navigator.clipboard.writeText(text).then(function() {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: 'white',
        customClass: {
          popup: 'colored-toast'
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true
      })
      Toast.fire({
        icon: 'success',
        title: 'Copied to clipboard!'
      })
    })
  }


}
