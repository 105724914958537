import React, {Component, useState} from 'react'
import {Modal, Badge, Button, Card, Form, Nav, Tab, Tabs, Row, Col, Dropdown} from 'react-bootstrap'
import axios from "axios";
import Loader from "../../shared/Loader";
import helpers from "../../shared/layouts/helpers";
import Swal from "sweetalert2";
import AuthService from "../../services/auth-service";

export default class ConnectTo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            username: "",
            password: "",
            country: helpers.getUser() ? helpers.getUser().connectTo : "",
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    componentDidMount() {
        axios.get(helpers.getAPI() + '/partner/api/users/get/'+helpers.getUser().id).then(res => {
            this.setState({
                username: res.data.username,
                loading: false
            });
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true});
        let user = helpers.getUser();
        user.switching = true;
        helpers.setUser(user);

        AuthService.login(this.state.username, this.state.password, this.state.country).then(
            () => {
                if (this.state.country && this.state.country.includes('-')) {
                    let host = this.state.country.includes("R-") ? "refurbee" : "mentech";
                    window.location = "https://partner."+host+".io";
                } else {
                    window.location.reload();
                }
            },
            error => {
                this.setState({
                    loading: false,
                    error: true,
                });
                user.switching = false;
                helpers.setUser(user);

            }
        );
    };

    handleShow = () => (
        this.setState({show: true})
    );

    handleClose = () => (
        this.setState({show: false})
    );

    logout(e) {
        axios.get(helpers.getAPI()  + '/partner/api/logout').then(res => {
            AuthService.logout();
        }).catch(res => {
            AuthService.logout();
        })
    }

    render(){
        return (
            <>
                <Dropdown.Item onClick={this.handleShow}>
                    <i className="ion ion-ios-swap"></i> &nbsp; Connected to <small>({helpers.getUser().connectTo})</small>
                </Dropdown.Item>


                <Modal show={this.state.show} onHide={this.handleClose} backdrop="static" size={'sm'}>
                    <Form onSubmit={this.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Switch connect to
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            {this.state.loading && <Loader/>}
                            {this.state.error && <Card bg={"danger text-white mb-4"}>
                                <Card.Body>
                                    Something went wrong.. We couldn't connect you to: {this.state.country}.
                                    <br/>
                                    Would you like to log out?
                                    <br/><br/>
                                    <Button onClick={() => this.logout()} variant={"dark"}>Log out</Button>
                                </Card.Body>
                            </Card>}
                            <Form.Group>
                                <Form.Label>Username</Form.Label>
                                <Form.Control name="username" value={this.state.username} onChange={this.handleInputChange}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="d-flex justify-content-between align-items-end">
                                    <div>Password</div>
                                </Form.Label>
                                <Form.Control name="password" type="password" value={this.state.password} onChange={this.handleInputChange}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="d-flex justify-content-between align-items-end">
                                    <div>Connect to</div>
                                </Form.Label>
                                <Form.Control required as={"select"} name={"country"} value={this.state.country} onChange={this.handleInputChange}>
                                    <option disabled value={""}>Please select an option</option>
                                    {JSON.parse(process.env.REACT_APP_API_LIST)[0][helpers.getHost()].map(item => (
                                        <option key={item.code} value={item.code}>{item.country}</option>
                                    ))}
                                    {(helpers.getUser().countryId = "DK56" && helpers.getHost() == "mentech") && <option key={"R-DK"} value={"R-DK"}>Refurbee</option>}
                                    {(helpers.getUser().countryId = "DK1" && helpers.getHost() == "refurbee") && <option key={"M-DK"} value={"R-DK"}>Mentech [Denmark]</option>}
                                    {(helpers.getUser().countryId = "DK1" && helpers.getHost() == "refurbee") && <option key={"M-NO"} value={"R-DK"}>Mentech [Norway]</option>}
                                    {(helpers.getUser().countryId = "DK1" && helpers.getHost() == "refurbee") && <option key={"M-SE"} value={"R-DK"}>Mentech [Sweden]</option>}
                                </Form.Control>
                            </Form.Group>
                            <small className={"text-muted"}> You are currently connected to: {helpers.getUser().connectTo}</small>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                            <Button type={"submit"} variant={"primary"}>Connect</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </>
        )
    }
}
